import _ from "lodash";

export function abbreviateNumber(number, decimal_places = 1, si_symbol = ["", "k", "M", "B", "T", "P", "E"]) {
  if (number == "") return number;
  if (!number) number = 0;
  let tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier == 0) return number;
  let suffix = si_symbol[tier];
  let scale = Math.pow(10, tier * 3);
  let scaled = number / scale;
  return scaled.toFixed(decimal_places) + suffix;
}

export function addCommas(number, decimal_places) {
  if (number == "") return number;
  if (!number) number = 0;
  if (typeof decimal_places === "number") number = Number.parseFloat(number).toFixed(decimal_places);
  var parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function bigCurrency(number, decimal_places) {
  return getCurrencySymbol() + abbreviateNumber(number, decimal_places);
}

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export function capitalize(value, onlyFirstLetter = false) {
  if (!value) return "";
  value = value.toString();
  let matches = value.match(/[À-ÿA-Za-z]*/g);
  matches.map((e, i) => {
    if (onlyFirstLetter && i > 0) {
      return;
    }
    value = value.replace(e, e.charAt(0).toUpperCase() + e.slice(1).toLowerCase());
  });
  return value;
}

export function cleanNumber(string) {
  if (string == null || string == "-") return "-";
  let isMinus = string.toString().indexOf("-") == 0;
  let r = String(string).replace(/[^0-9.]/g, "");
  let stop = r.indexOf(".");
  r = r.replace(/\./g, "");
  if (stop == 0) {
    r = "0" + r;
    stop++;
  }
  if (stop > 0 && stop < r.length) r = r.slice(0, stop) + "." + r.slice(stop);
  return r.length > 0 ? Number(isMinus ? "-" + r : r) : r;
}

export function currency(value, decimal_places = 2, currency) {
  currency = currency || process.env.VUE_APP_CURRENCY;
  let x = addCommas(value, decimal_places);
  const currencySymbol = getCurrencySymbol(currency);
  return `${currencySymbol}${x}`;
}

export function getCurrencySymbol(currencyCode) {
  currencyCode = currencyCode ? currencyCode : process.env.VUE_APP_CURRENCY;
  const symbols = { EUR: "€", GBP: "£", AUD: "$", undefined: "$" };
  return symbols[currencyCode];
}

export function objectToQueryString(data) {
  let query = [];
  for (const [key, value] of Object.entries(data)) {
    if (value != null) {
      query.push(`${key}=${value}`);
    }
  }
  return query.length == 0 ? "" : "?" + query.join("&");
}

export function percentage(value, decimal_places = 2) {
  let x = addCommas(value, decimal_places);
  return `${x}%`;
}

export function restrictDecimals(n, p = 2) {
  if (!n) n = 0;
  n = Number.parseFloat(n);
  return n % 1 == 0 ? n.toFixed(0) : n.toFixed(p);
}

export function stringToArray(str) {
  if (_.isString(str)) {
    str = [str];
  }
  return str;
}

export function stripPeriods(str) {
  let periodRegex = /[.]/gi;
  return _.isNil(str) ? "" : str.replace(periodRegex, "");
}

export default {
  abbreviateNumber,
  addCommas,
  bigCurrency,
  bytesToSize,
  capitalize,
  cleanNumber,
  currency,
  getCurrencySymbol,
  objectToQueryString,
  percentage,
  restrictDecimals,
  stringToArray,
  stripPeriods
};
