import Api from "./api.js";
import Colours from "./colours.js";
import Company from "./company.js";
import Dates from "./dates.js";
import Formatting from "./formatting.js";
import IndirectStorage from "./indirectStorage.js";
import Math from "./math.js";
import Resize from "./resize.js";
import Presets from "./presets";
import SavedSession from "./savedSession.js";
import Utils from "./utils.js";
import Validations from "./validations.js";

export default {
  Api,
  Colours,
  Company,
  Dates,
  Formatting,
  IndirectStorage,
  Math,
  Resize,
  Presets,
  SavedSession,
  Utils,
  Validations
};
