import { reactive, ref } from "vue";
import axios from "axios";
import Functions from "@/functions";

const cleanState = {
  busy: ref(false),
  luxScores: reactive({})
};

const state = {
  cleanState,
  busy: cleanState.busy,
  luxScores: cleanState.luxScores
};

const getters = {
  busy: state => state.busy,
  luxScores: state => state.luxScores,
  score: state => id => state.luxScores[id] || {}
};

const mutations = {
  SET_BUSY(state, data) {
    state.busy = data;
  },
  SET(state, { code, data }) {
    state.luxScores[code] = data;
  },
  SET_PROP(state, { code, prop, data }) {
    state.luxScores[code][prop] = data;
  }
};

const actions = {
  getChanges({ commit }, { profile, timeframe = "1w" }) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `LuxScore/${profile}/Changes/${timeframe}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET", { profile, data: response.data.data });
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      )
      .finally(() => {
        commit("SET_BUSY", false);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
