<template>
  <div id="app">
    <router-view name="header" id="header" v-slot="{ Component }">
      <transition name="maxH">
        <component :is="Component" />
      </transition>
    </router-view>
    <router-view name="subHeader" id="subHeader" v-slot="{ Component }">
      <transition name="maxH">
        <component :is="Component" />
      </transition>
    </router-view>
    <router-view id="page" v-slot="{ Component }">
      <transition mode="out-in" name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
    <router-view name="footer" id="footer" v-slot="{ Component }">
      <transition name="maxH">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="scss">
@import "@/style/app.scss";
@import "@/style/_core.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: auto; //IE 11 fix for flexbox support
  @supports (height: 100%) {
    height: 100%;
  }
  & > * {
    flex-grow: 1;
  }

  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  color: $color-black;
  background: $color-background;
}

#header {
  position: sticky;
  top: 0;
  z-index: z("header");
  flex-grow: 0;
}

#subHeader {
  z-index: z("subheader");
  flex-grow: 0;
}

#footer {
  position: sticky;
  bottom: 0;
  flex-grow: 0;
  z-index: z("footer");
}

#zmmtg-root {
  z-index: z("full-page-overlay");
}
</style>
