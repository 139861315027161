import Formatting from "@/functions/formatting.js";
import Validations from "@/functions/validations.js";

const Currency = {
  type: "tel",
  min: 0,
  max: 100000000,
  step: 0.01,
  decimals: 2,
  maxlength: 9,
  allowNull: false,
  validation: value => {
    return Validations.isNumberRangeValid(value, { min: 0, max: 100000000 });
  },
  validation_message: {
    path: "common.validation.range_invalid",
    min: Formatting.addCommas(0),
    max: Formatting.addCommas(100000000)
  },
  inputmode: "numeric",
  pattern: "[0-9]*",
  forceinput: /^-?[0-9]*\.?[0-9]{0,2}/,
  prefix: Formatting.getCurrencySymbol(),
  blurFormat: value => {
    if (value === null || value === "" || (isNaN(value) && value !== "-" && value !== ".")) return "";
    if (value === "-" || value === ".") return value;
    return Formatting.addCommas(Formatting.restrictDecimals(Formatting.cleanNumber(value), 2));
  },
  displayFormat: value => {
    if (value === null || value === "" || (isNaN(value) && value !== "-" && value !== ".")) return "";
    if (value === "-" || value === ".") return value;
    return Formatting.restrictDecimals(value, 2);
  }
};

const Date = {
  label: "",
  placeholder: "",
  type: "date"
};

const DisplayName = {
  icon: "profile",
  label: "Nickname",
  type: "text",
  max: 250,
  maxlength: 250,
  validation: v => {
    return v.length > 1;
  },
  validation_message: "common.validation.required_invalid"
};

const Email = {
  icon: "email",
  label: "Email",
  type: "text", //chrome blocks selectionStart/selectionEnd in forceinput on input[type="email"]
  validation: value => Validations.isEmailValid(value),
  validation_message: "common.validation.email_invalid",
  forceinput: /^[A-Za-z0-9.,;:'"`~!#£$%&*+=?^\-_\\{\[(<|>)\]}]+[@]?[A-Za-z0-9.\-_]*/ //eslint-disable-line
};

const Field = {
  type: "text"
};

const RequiredField = {
  ...Field,
  validation: v => v.length > 0,
  validation_message: "common.validation.required_invalid"
};

const Mobile = {
  label: "Mobile",
  type: "text",
  minlength: 8,
  maxlength: 16,
  validation: value => Validations.isRequiredValid(value),
  validation_message: "common.validation.required_invalid",
  invalid_message: "common.validation.required_invalid",
  forceinput: /^[+]?[\d]*/
};

const Integer = {
  label: "",
  placeholder: "",
  type: "number",
  inputmode: "numeric",
  numberIcons: true,
  step: 1,
  decimals: 0,
  pattern: "[0-9]*",
  forceinput: /^-?[0-9]*/
};

const Number = Object.assign(
  { ...Integer },
  {
    forceinput: /^-?[0-9]*\.?[0-9]{0,2}/
  }
);

const Year = Object.assign(
  { ...Number },
  {
    min: 1800,
    max: 2100,
    step: 1,
    decimals: 0,
    maxlength: 4,
    validation: value =>
      Validations.isNumberRangeValid(value, {
        min: 1800,
        max: 2100
      }),
    validation_message: function() {
      return {
        path: "common.validation.range_invalid",
        min: 1800,
        max: 2100
      };
    },
    pattern: "[0-9]*",
    forceinput: /^[0-9]{0,4}/
  }
);

const Password = {
  icon: "password",
  label: "Password",
  type: "password",
  min: 8,
  validation: value => Validations.isPasswordValid(value),
  validation_message: "common.validation.password_invalid",
  forceinput: /[À-ÿA-z0-9!@#$%^&*{}+\-_()~,.;:?]*/
};

export default {
  Currency,
  DisplayName,
  Date,
  Email,
  Field,
  RequiredField,
  Mobile,
  Integer,
  Number,
  Year,
  Password
};
