import _ from "lodash";
import Constants from "@/assets/constants";

export function isStringDateValid(date) {
  let x = new Date(date);
  return !!date && isValidDate(x);
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function dateText(date, dateFormat = Constants.DATE_FORMAT.DEFAULT) {
  if (!isStringDateValid(date)) {
    return "-";
  }
  if (typeof date == "string" && date.indexOf("T") == -1) {
    date += "T00:00:00-00:00";
  }

  return formatDateTime(date, dateFormat);
}

export function dateTextAtTimeFormat(
  date,
  { dateFormat = Constants.DATE_FORMAT.DEFAULT, timeFormat = Constants.TIME_FORMAT.DEFAULT } = {}
) {
  if (!isStringDateValid(date)) {
    return "-";
  }
  return `${dateText(date, dateFormat)} at ${timeText(date, timeFormat)}`;
}

export function friendlyDateText(date) {
  if (!isStringDateValid(date)) {
    return "-";
  }
  let now = new Date();
  let compare = new Date(date);
  let offset = now.getTimezoneOffset();
  compare.setMinutes(compare.getMinutes() - offset);
  let minutesBack = Math.floor((now.getTime() - compare.getTime()) / 60000);
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  function isSameDay(d, e) {
    return d.getFullYear() === e.getFullYear() && d.getMonth() === e.getMonth() && d.getDate() === e.getDate();
  }

  if (minutesBack < 1) {
    return "Seconds ago";
  } else if (minutesBack < 2) {
    return "A minute ago";
  } else if (minutesBack < 60) {
    return minutesBack + " minutes ago";
  } else if (minutesBack < 120) {
    return "1 hour ago";
  } else if (minutesBack < 720) {
    return Math.floor(minutesBack / 60) + " hours ago";
  } else if (isSameDay(compare, now)) {
    return "Today";
  } else if (isSameDay(compare, yesterday)) {
    return "Yesterday";
  }

  return dateText(date);
}

export function friendlyDateTimeText(date) {
  if (!isStringDateValid(date)) {
    return "-";
  }
  let now = new Date();
  let compare = new Date(date);
  let offset = now.getTimezoneOffset();
  compare.setMinutes(compare.getMinutes() - offset);
  let minutesBack = Math.floor((now.getTime() - compare.getTime()) / 60000);
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  function isSameDay(d, e) {
    return d.getFullYear() === e.getFullYear() && d.getMonth() === e.getMonth() && d.getDate() === e.getDate();
  }

  if (minutesBack < 1) {
    return "Seconds ago";
  } else if (minutesBack < 2) {
    return "A minute ago";
  } else if (minutesBack < 60) {
    return minutesBack + " minutes ago";
  } else if (minutesBack < 120) {
    return "1 hour ago";
  } else if (minutesBack < 720) {
    return Math.floor(minutesBack / 60) + " hours ago";
  } else if (isSameDay(compare, now)) {
    return "Today at " + timeText(date);
  } else if (isSameDay(compare, yesterday)) {
    return "Yesterday at " + timeText(date);
  }

  return dateTextAtTimeFormat(date);
}

// Implemented a subset of the patterns listed here https://date-fns.org/v2.22.1/docs/format
function formatDateTime(ISOString, format) {
  let result = format;

  let date = new Date(formatUtcString(ISOString));
  let value;

  //a
  let am = date.getHours() < 12;
  value = am ? "AM" : "PM";
  result = result.replace("a", value);

  //yyyy
  value = date.getFullYear();
  result = result.replace("yyyy", value);

  //MMM
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  value = months[date.getMonth()];
  result = result.replace("MMM", value);

  //MM
  value = (`0` + (date.getMonth() + 1)).slice(-2);
  result = result.replace("MM", value);

  //dd
  value = (`0` + date.getDate()).slice(-2);
  result = result.replace("dd", value);

  //HH
  value = (`0` + date.getHours()).slice(-2);
  result = result.replace("HH", value);

  //h
  value = date.getHours() % 12;
  result = result.replace("h", value);

  //mm
  value = (`0` + date.getMinutes()).slice(-2);
  result = result.replace("mm", value);

  //SSS
  value = (`00` + date.getMilliseconds()).slice(-3);
  result = result.replace("SSS", value);

  //ss
  value = (`0` + date.getSeconds()).slice(-2);
  result = result.replace("ss", value);

  //do
  let dateOfMonth = ("0" + date.getDate()).slice(-2);
  let suffix = "th";
  if (dateOfMonth[0] != "1") {
    if (dateOfMonth[1] == "1") suffix = "st";
    if (dateOfMonth[1] == "2") suffix = "nd";
    if (dateOfMonth[1] == "3") suffix = "rd";
  }
  value = date.getDate() + suffix;
  result = result.replace("do", value);

  //DDD
  var weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  result = result.replace("GGG", weekdays[date.getDay()]);

  //'T'
  result = result.replace("'T'", "T");

  //'Z'
  result = result.replace("'Z'", "Z");

  return result;
}

function formatUtcString(str) {
  if (_.isString(str) && !str.endsWith("Z")) {
    str = str + "Z";
  }
  return str;
}

export function generateUUID() {
  var d = new Date().getTime();
  if (typeof performance !== "undefined" && typeof performance.now === "function") {
    d += performance.now();
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function timeText(date, timeFormat = Constants.TIME_FORMAT.DEFAULT) {
  if (!isStringDateValid(date)) {
    return "-";
  }
  return formatDateTime(date, timeFormat);
}

export function yearsFromToday(v) {
  let year = new Date().getFullYear();
  return year + v;
}

export function yyyymmddZeroFix(s) {
  let parts = s
    .split("T")[0]
    .split("-")
    .map(p => Number(p));
  return [parts[0], ("0" + parts[1]).slice(-2), ("0" + parts[2]).slice(-2)].join("-");
}

export default {
  isStringDateValid,
  dateText,
  dateTextAtTimeFormat,
  friendlyDateText,
  friendlyDateTimeText,
  generateUUID,
  timeText,
  yearsFromToday,
  yyyymmddZeroFix
};
