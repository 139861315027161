import Constants from "@/assets/constants.js";
import * as storage from "./indirectStorage";

function set(authtoken, userid) {
  storage.local.setItem(Constants.localStorage.SESSION_KEY, authtoken);
  storage.local.setItem(Constants.localStorage.USER_ID_KEY, userid);
}

function getAuthToken() {
  return storage.local.getItem(Constants.localStorage.SESSION_KEY) || "";
}

function getUserId() {
  return storage.local.getItem(Constants.localStorage.USER_ID_KEY) || null;
}

function removeAuthToken() {
  storage.local.removeItem(Constants.localStorage.SESSION_KEY);
}

function removeUserId() {
  storage.local.removeItem(Constants.localStorage.USER_ID_KEY);
}

function clear() {
  removeAuthToken();
  removeUserId();
}

export default {
  set,
  getAuthToken,
  getUserId,
  clear
};
