import _ from "lodash";

export function changeIcon(company, prop = "priceChange1D") {
  let value = company[prop];
  return value == 0 ? "minus" : value < 0 ? "arrow-down" : "arrow-up";
}

export function luxToLabel(lux) {
  if (_.isNull(lux)) {
    return "";
  }
  if (lux >= 8.5) {
    return "Radiant";
  }
  if (lux >= 7) {
    return "Bright";
  }
  if (lux >= 4) {
    return "Neutral";
  }
  return "Dull";
}

export function trendClass(company, prop = "priceChange1D") {
  return {
    "common-color-success": company[prop] > 0,
    "common-color-gray": company[prop] == 0,
    "common-color-error": company[prop] < 0
  };
}

export default {
  changeIcon,
  luxToLabel,
  trendClass
};
