import _ from "lodash";
import axios from "axios";
import axiosRetry from "axios-retry";
import SavedSession from "./savedSession.js";

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: error => {
    if (typeof error.response == "undefined" && error.message == "Network Error") return true;
    return _.get(error, "response.status") == 429;
  },
  retries: 5
});

function errorInterceptor(router) {
  axios.interceptors.request.use(
    function(config) {
      config.requestStartTime = Date.now();
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (_.has(error, "config.requestStartTime")) {
        error.config.requestTime = Date.now() - error.config.requestStartTime;
      }

      if (_.get(error, "response.status", "") == 401) {
        return router.push("/logout");
      }
      return Promise.reject(error);
    }
  );
}

export function getHeaders({ version } = {}) {
  const headers = {
    "Content-Type": "application/json"
  };
  if (version) {
    headers["X-Api-Version"] = version;
  }

  let authToken = SavedSession.getAuthToken();
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  return headers;
}

export default {
  errorInterceptor,
  getHeaders
};
