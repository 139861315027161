import C from "@/assets/constants.js";
const routes = [];

routes.push({
  path: "/lux-settings/:step?",
  name: "LuxSettings",
  components: {
    header: () => import(/* webpackChunkName: "client" */ "@/components/molecules/Header.vue"),
    default: () => import(/* webpackChunkName: "company" */ "@/views/LuxSettings/LuxSettings.vue")
  },
  props: {
    default: r => ({
      step: r.params.step
    })
  },
  meta: {
    access: [C.ACCESS.CLIENT, C.ACCESS.ADMIN]
  }
});

export default {
  routes
};
