import { reactive, ref } from "vue";
import axios from "axios";
import _ from "lodash";
import Functions from "@/functions";
import Constants from "@/assets/constants";

const cleanState = {
  busy: ref(false),
  notices: reactive([])
};

const state = {
  cleanState,
  busy: cleanState.busy,
  notices: cleanState.notices
};

const getters = {
  busy: state => state.busy,
  notices: state => state.notices,
  byTopic: state => topic => {
    if (!topic) {
      topic = "default";
    }
    return _.filter(state.notices, n => n.topic == topic);
  },
  byId: state => id => _.find(state.notices, n => n.id == id) || {},
  isEmpty: (state, getters) => !getters.busy && getters.notices.length == 0
};

const mutations = {
  SET_BUSY(state, data) {
    state.busy = data;
  },
  SET(state, data) {
    state.notices = data;
  },
  SET_SINGLE(state, data) {
    let single = _.findIndex(state.notices, n => n.id == data.id);
    if (single > -1) {
      state.notices[single] = data;
    } else {
      state.notices.push(data);
    }
  }
};

const actions = {
  getNotices({ commit }, data) {
    commit("SET_BUSY", true);
    if (!data.asOf) {
      data.asOf = Functions.Dates.dateText(new Date(), Constants.DATE_FORMAT.ISO_EXTENDED);
    }
    let query = Functions.Formatting.objectToQueryString(data);
    return axios
      .request({
        url: `/Notice/Get${query}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET", response.data);
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },
  editNotice({ commit }, data) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/Notice/Edit`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_BUSY", false);
          commit("SET_SINGLE", response.data);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
