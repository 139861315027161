import { reactive, ref } from "vue";
import axios from "axios";
import Functions from "@/functions";

const cleanState = {
  busy: ref(false),
  questions: reactive({}),
  pagination: reactive({})
};

const state = {
  cleanState,
  busy: cleanState.busy,
  questions: cleanState.questions,
  pagination: cleanState.pagination
};

const getters = {
  busy: state => state.busy,
  questions: state => state.questions,
  byCode: state => code => {
    if (!code) {
      code = "default";
    }
    return state.questions[code] || [];
  },
  byId: state => id => {
    for (const [key, value] of Object.entries(state.questions)) {
      let question = value.find(q => q.messageId == id) || key;
      if (question) {
        return question;
      }
    }
    return {};
  },
  isEmpty: (state, getters) => code => !getters.busy && getters.byCode(code).length == 0,
  pagination: state => code => {
    if (!code) {
      code = "default";
    }
    return state.pagination[code] || false;
  }
};

const mutations = {
  SET_BUSY(state, data) {
    state.busy = data;
  },
  SET(state, { code, data }) {
    state.questions[code] = data;
  },
  SET_SINGLE(state, { code, data }) {
    if (!state.questions[code]) {
      state.questions[code] = [];
    }
    let questions = state.questions[code];
    let question = questions.findIndex(q => q.messageId === data.messageId);
    if (question >= 0) {
      state.questions[code][question] = data;
    } else {
      state.questions[code].push(data);
    }
  },
  SET_PAGINATION(state, { code, data }) {
    state.pagination[code] = data;
  }
};

const actions = {
  getQuestions({ commit }, data) {
    commit("SET_BUSY", true);
    let query = Functions.Formatting.objectToQueryString(data);
    return axios
      .request({
        url: `/CompanyQuestions/GetQuestions${query}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET", { code: data.companyCode || "default", data: response.data.messages });
          commit("SET_PAGINATION", { code: data.companyCode || "default", data: response.data.moreAvailable });
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },
  getQuestion({ commit }, data) {
    if (!data.id) throw "id Required";
    commit("SET_BUSY", true);
    let query = Functions.Formatting.objectToQueryString(data);
    return axios
      .request({
        url: `/CompanyQuestions/GetQuestion${query}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_SINGLE", { code: response.data.companyCode || "default", data: response.data.message });
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },
  ask({ commit }, data) {
    if (!data.companyCode) throw "companyCode Required";
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/CompanyQuestions/AskNewQuestion`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },
  answer({ commit }, data) {
    if (!data.questionId) throw "questionId Required";
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/CompanyQuestions/AnswerQuestion`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },
  editQuestion({ commit, dispatch }, data) {
    if (!data.messageId) throw "messageId Required";
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/CompanyQuestions/EditQuestion`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Functions.Api.getHeaders()
      })
      .then(
        () => {
          commit("SET_BUSY", false);
          return dispatch("getQuestion", { id: data.messageId });
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },
  deleteQuestion({ commit }, data) {
    if (!data.messageId) throw "messageId Required";
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/CompanyQuestions/DeleteQuestion`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
