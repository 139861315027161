const constants = Object.freeze({
  ACCESS: {
    NONE: "None",
    PUBLIC: "Public",
    INACTIVE: "Inactive",
    UNPAID: "Unpaid",
    CLIENT: "Member",
    ADMIN: "Administrator"
  },
  ACCESS_HIERARCHY: ["PUBLIC", "CLIENT", "ADMIN"],
  BREAKPOINTS: {
    zero: 0,
    tiny: 320,
    little: 360,
    small: 400,
    mobile: 480,
    medium: 560,
    phablet: 640,
    tablet: 768,
    desktop: 992,
    wide: 1280,
    ultrawide: 1500
  },
  DATE_FORMAT: {
    DAY_OF: "do MMM yyyy",
    DEFAULT: "dd/MM/yyyy",
    ISO: "yyyy-MM-dd",
    ISO_EXTENDED: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
    NOTICE: "GGG dd/MM/yyyy",
    SHORT_DAY_OF: "do MMM"
  },
  KEYCODE: {
    BACKSPACE: 8,
    DELETE: 46,
    DOWN: 40,
    ENTER: 13,
    LEFT: 37,
    RIGHT: 39,
    SHIFT: 16,
    SPACEBAR: 32,
    TAB: 9,
    UP: 38
  },
  InputTypeForSelectionAttr: ["text", "search", "url", "tel", "password"],
  localStorage: {
    SESSION_KEY: "session-token",
    USER_ID_KEY: "userId"
  },
  NOTICE_TOPIC: {
    UPCOMING: "upcoming"
  },
  ORDER_DIRECTION: {
    ASC: "asc",
    DESC: "desc"
  },
  REGEX: {
    ABSOLUTE_NUMBER: /^[0-9]*/,
    AUTHTOKEN: /^[A-z0-9]+$/,
    CURRENCY: /^-?[0-9]*\.?[0-9]{0,2}/,
    EMAIL: /^[A-Za-z0-9.,;:'"`~!#£$%&*+=?^\-_\/\\{\[(<|>)\]}]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([A-Za-z\-0-9]+\.)+[A-Za-z]{2,}))$/, //eslint-disable-line
    METRIC: /^Metrics\.\w+$/,
    NAME: /^[À-ÿA-Za-z]+(?:[À-ÿA-Za-z]+|([-' ])(?![-' ]))*/,
    NUMBER: /^-?[0-9]*/,
    PASSWORD: /((?=.*\d)(?=.*[A-Z])(?=.*[a-z])[À-ÿA-z0-9!@#$%^&*{}+\-_()~,.;:?]{8,})/,
    PHONE: /^\d{0,4}([-.\s]?\d{3,4}[-.\s]?\d{3}[-.\s]?\d{3})$/,
    SAFE_HTML: /(?![\s\S]*<script)(?![\s\S]*script\=)(?![\s\S]*javascript:)(?![\s\S]*<!--)(?![\s\S]*onmouseover\=)(?![\s\S]*attr\=)(?![\s\S]*expression\=)[\s\S]*/, //eslint-disable-line
    URL: /^(https?|ftp):\/\/([-\.~_!a-zA-Z0-9:%*+=$&'(),;]+@)?(((([0-9]+\.){3}[0-9]+)|([-a-zA-Z0-9]{1,63}\.){0,3}([a-zA-Z][-a-zA-Z0-9]{1,63}))(:[0-9]+)?)(\/[-a-zA-Z0-9@:%_\+\.~#?&//=\(\)]*)?$/ //eslint-disable-line
  },
  TIME_FORMAT: {
    DEFAULT: "HH:mm",
    WITH_AM_PM: "h:mm a"
  }
});

export default constants;
