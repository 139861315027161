export function nonLinearPercentage(value, strength = "2") {
  //value 0 to 100
  let v = value / 10 - 5;
  let nl = 10 - 10 * (1 / (1 + Math.pow(strength, v)));
  return nl * 10;
}

export default {
  nonLinearPercentage
};
