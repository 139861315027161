export default {
  en: {
    project: {
      name: "Spotee",
      slogan: "shining the light on shares"
    },
    common: {
      validation: {
        can_not_include_space: "Cannot contain spaces.",
        can_not_include_names: "Cannot contain your first name or last name.",
        can_not_include_username: "Cannot contain your username.",
        email_invalid: "Enter a valid email",
        email_in_use: "This email is already in use.",
        generic: "Error, please try again",
        length_invalid: "Enter a value of {length} characters",
        length_range_invalid: "Enter a value between {min} and {max} characters",
        login_fail: "You have entered an invalid username or password",
        new_retype_password_required: "Please confirm your new password",
        nickname_in_use: "This nickname is already in use.",
        password_invalid:
          "The password must be at least {min} characters long and contain each of a number plus uppercase and lowercase text.<br/>For example gThy6Rec3",
        password_not_match: "Your passwords don't match, please check again.",
        password_old_vs_new: "Your password has to be different from the old one, please try again.",
        password_required: "Please enter your password",
        phone_invalid: "Please enter valid phone number.",
        range_invalid: "Enter a value between {min} and {max}",
        required_invalid: "This field is required",
        url_invalid: "Please enter valid url."
      }
    },
    page: {
      Company: {
        Financial: {
          Current: {
            EPS12M: "Earnings per share",
            ROETR12PC: "Return on equity",
            ROATR12PC: "Return on assets",
            DPSLastReported: "Dividends per share",
            ROIPC: "Return on Invested Capital (ROI)",
            PriceAdjusted: "Price",
            DividendPayoutRatioPC: "Payout Ratio",
            PE: "PE ratio",
            SalesRevenue: "Revenue",
            FreeCashFlowPerShare: "Free cash flow per share",
            CashFlowPerShare: "Cash flow per share",
            CashFlowNet: "Net operating Cash flow",
            EBITDA: "EBITDA",
            OperatingProfitMarginPC: "Operating margin",
            NetProfitMarginPC: "Net profit margin",
            DebtNet: "Net debt",
            ShareholderEquity: "Shareholders Equity",
            EBITToInterestRatio: "Interest coverage",
            QuickRatio: "Quick ratio",
            ["Dividend Yield"]: "Dividend Yield",
            ["Gross Dividend Yield"]: "Gross Dividend Yield",
            ["Price to Cashflow"]: "Price to Cashflow",
            ["Price to Sales"]: "Price to Sales",
            ["EV/FCF"]: "EV/FCF",
            ["Gross Margin"]: "Gross Margin",
            ["Debt to Equity"]: "Debt to Equity"
          },
          Forecast: {
            EPS12M: "Earnings per share (EPS)",
            EPSForecastFY1: "Earnings per share",
            ROEFD12PC: "Return on equity",
            ROAFD12PC: "Return on assets",
            DPSForecastFY1: "Dividends per share",
            PEForecast12M: "Price to earnings",
            SalesFD12: "Sales",
            FreeCashFlowPerShareFD12: "Free cash flow per share",
            CashFlowPerShareFD12: "Cash flow per share",
            EBITDAPerShareFD12: "EBITDA per share",
            ["Dividend Yield"]: "Dividend Yield",
            ["Gross Dividend Yield"]: "Gross Dividend Yield",
            ["Payout Ratio"]: "Payout ratio",
            ["Price to Sales"]: "Price to sales",
            ["Price to Cashflow"]: "Price to cash flow",
            ["Cashflow PS"]: "Cash flow per share"
          },
          Recommendation: {
            PriceAdjusted: "Price",
            ConsensusRecommendation: "Recommendation",
            PriceTargetNumber: "Number of analysts",
            PriceTargetMean: "Price target",
            PriceTargetHigh: "Target high",
            PriceTargetLow: "Target low",
            StrongBuyCount: "Strong buy",
            BuyCount: "Buy",
            HoldCount: "Hold",
            SellCount: "Sell",
            StrongSellCount: "Strong sell",
            PremiumPC: "Premium | Discount"
          }
        }
      },
      ForgotSuccess: {
        title: "Check your mail",
        description:
          "<p>You will receive an email shortly at the provided address that will allow you to create a new password.</p><p>Please check your junk folder if after a few minutes you still don't receive the message.</p>"
      },
      ResetPasswordSuccess: {
        title: "Password reset successfully",
        description: "<p>Your password has been updated, you can now login with your new credentials.</p>"
      }
    }
  }
};
