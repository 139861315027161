import _ from "lodash";
import Constants from "@/assets/constants.js";

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

function isEmailValid(s) {
  var re = Constants.REGEX.EMAIL;
  return re.test(s);
}

function isNameValid(s, { min = 2 }) {
  if (!s || s.length == 0) {
    return false;
  }
  if (s.length < min) {
    return false;
  }
  return true;
}

function isNumberRangeValid(s, { min, max, step }) {
  let minValid = isNumberValid(min) ? parseFloat(s) >= min : true;
  let maxValid = isNumberValid(max) ? parseFloat(s) <= max : true;
  let stepValid = isNumberValid(step) ? Math.floor(100 * (s % parseFloat(step))) == 0 : true;

  return isRequiredValid(s) && isNumberValid(parseFloat(s)) && minValid && maxValid && stepValid;
}

function isNumberValid(s) {
  return _.isNumber(s);
}

function isLengthValid(s, { minlength, maxlength }) {
  return isRequiredValid(s) && s.length >= minlength && s.length <= maxlength;
}

function isSpecificLengths(s = "", { allowedLengths }) {
  return isRequiredValid(s) && allowedLengths.indexOf(s.length) > -1;
}

function isPhoneValid(s, regexOverride = null) {
  var re = regexOverride || Constants.REGEX.PHONE;
  return re.test(s);
}

function isPasswordValid(s) {
  var re = Constants.REGEX.PASSWORD;
  return re.test(s);
}

function isRequiredAddressTextValid(s, regexOverride = null) {
  if (!isRequiredValid(s)) return { path: "common.validation.required_invalid" };

  if (regexOverride) {
    if (!regexOverride.test(s)) return { path: "common.validation.safe_text_invalid" };
  }

  return true;
}

function isRequiredSafeHTMLStringValid(s) {
  if (!isRequiredValid(s)) return { path: "common.validation.required_invalid" };

  if (!isSafeHTMLString(s)) return { path: "common.validation.safe_html_invalid" };

  return true;
}

function isRequiredPhoneNumber(s, regexOverride = null) {
  if (!isRequiredValid(s)) return { path: "common.validation.required_invalid" };

  if (!isPhoneValid(s, regexOverride)) return { path: "common.validation.phone_invalid" };

  return true;
}

function isRequiredValid(s) {
  return (_.isString(s) && !_.isEmpty(s) && s.trim().length > 0) || _.isNumber(s) || _.isBoolean(s);
}

function isSafeHTMLString(s) {
  var re = Constants.REGEX.SAFE_HTML;
  return re.test(s);
}

function isValidAuthtoken(authtoken) {
  let rx = Constants.REGEX.AUTHTOKEN;
  return rx.test(authtoken) && authtoken.length == 32;
}

function isValidURL(text) {
  const urlRegex = Constants.REGEX.URL;
  return urlRegex.test(text);
}

export default {
  hasQueryParams,
  isEmailValid,
  isNameValid,
  isNumberRangeValid,
  isNumberValid,
  isLengthValid,
  isSpecificLengths,
  isPhoneValid,
  isPasswordValid,
  isRequiredAddressTextValid,
  isRequiredSafeHTMLStringValid,
  isRequiredPhoneNumber,
  isRequiredValid,
  isSafeHTMLString,
  isValidAuthtoken,
  isValidURL
};
