import { reactive, ref } from "vue";
import axios from "axios";
import Api from "@/functions/api.js";

const cleanState = {
  busy: ref(false),
  watchlists: reactive([])
};

const state = {
  cleanState,
  busy: cleanState.busy,
  watchlists: cleanState.watchlists
};

const getters = {
  busy: state => state.busy,
  watchlists: state => state.watchlists,
  isEmpty: state => !state.busy && state.watchlists.length == 0
};

const mutations = {
  SET_BUSY(state, data) {
    state.busy = data;
  },
  SET(state, data) {
    state.watchlists = data;
  }
};

const actions = {
  getWatchlists({ commit }, name = "default") {
    commit("SET_BUSY", true);
    let query = "";
    if (name) {
      query = `?name=${name}`;
    }
    return axios
      .request({
        url: `/Watchlist/Get${query}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Api.getHeaders()
      })
      .then(
        response => {
          commit("SET", response.data.codes);
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },
  editWatchlists({ commit }, { name = "default", toAdd, toRemove }) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/Watchlist/Edit`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data: {
          name,
          toAdd,
          toRemove
        },
        headers: Api.getHeaders()
      })
      .then(
        response => {
          commit("SET", response.data.codes);
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
