import { reactive, ref } from "vue";
import axios from "axios";
import _ from "lodash";
import Constants from "@/assets/constants";
import Functions from "@/functions";

const cleanState = {
  busy: ref(false),
  companys: reactive({}),
  searchResults: reactive([]),
  improvements: reactive([])
};

const state = {
  cleanState,
  busy: cleanState.busy,
  companys: cleanState.companys,
  searchResults: cleanState.searchResults,
  improvements: cleanState.improvements
};

const getters = {
  busy: state => state.busy,
  companys: state => state.companys,
  company: state => code => state.companys[code] || {},
  searchResults: state => state.searchResults,
  improvements: state => state.improvements
};

const mutations = {
  SET_BUSY(state, data) {
    state.busy = data;
  },
  SET(state, { code, data }) {
    state.companys[code] = data;
  },
  SET_PROP(state, { code, prop, data }) {
    state.companys[code][prop] = data;
  },
  SET_MERGE(state, { code, prop, data }) {
    state.companys[code][prop] = _.merge(state.companys[code][prop], data);
  },
  SET_SEARCH(state, data) {
    state.searchResults = data;
  },
  CLEAR_SEARCH(state) {
    state.searchResults = cleanState.searchResults;
  },
  SET_DOC_LOAD(state, { code, docId, loading }) {
    let i = state.companys[code].announcements.findIndex(a => a.documentId == docId);
    state.companys[code].announcements[i].loading = loading;
  },
  SET_IMPROVEMENTS(state, data) {
    state.improvements = data;
  }
};

const actions = {
  search({ commit }, text) {
    commit("SET_BUSY", true);
    if (text.length < 2) {
      commit("CLEAR_SEARCH");
      commit("SET_BUSY", false);
      return Promise.resolve();
    } else {
      return axios
        .request({
          url: `Company/Search?text=${text}`,
          baseURL: process.env.VUE_APP_API_ENDPOINT,
          method: "get",
          headers: Functions.Api.getHeaders()
        })
        .then(
          response => {
            commit("SET_SEARCH", response.data.data);
            return response;
          },
          error => {
            return Promise.reject(error);
          }
        )
        .finally(() => commit("SET_BUSY", false));
    }
  },

  details({ commit, dispatch, getters }, { code, fullDetails = false }) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `Company/Details?code=${code}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET", { code, data: response.data.data });
          if (fullDetails == true) {
            let fullCode = _.get(getters["company"](code), "information.companyCode", code);
            return Promise.all([
              dispatch("recentNews", { code: fullCode }),
              dispatch("financials", { code: fullCode })
            ]);
          } else {
            return response;
          }
        },
        error => {
          return Promise.reject(error);
        }
      )
      .finally(() => {
        commit("SET_BUSY", false);
      });
  },

  announcements({ commit }, { code, from, to, priceSensitiveOnly = false, count = 10 }) {
    if (!from) {
      let past = new Date();
      past.setFullYear(past.getFullYear() - 1);
      from = Functions.Dates.dateText(past, Constants.DATE_FORMAT.ISO_EXTENDED);
    }

    if (!to) {
      let today = new Date();
      to = Functions.Dates.dateText(today, Constants.DATE_FORMAT.ISO_EXTENDED);
    }

    let query = Functions.Formatting.objectToQueryString({ from, to, priceSensitiveOnly, count });
    return axios
      .request({
        url: `Company/${code}/Announcements${query}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_PROP", {
            code: Functions.Utils.cleanCode(code),
            prop: "announcements",
            data: response.data.data
          });
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },
  announcementDoc({ commit }, { code, docId }) {
    commit("SET_DOC_LOAD", { code, docId, loading: true });
    return axios
      .request({
        url: `Company/AnnouncementDocument/${docId}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_DOC_LOAD", { code, docId, loading: false });
          return response;
        },
        error => {
          commit("SET_DOC_LOAD", { code, docId, loading: false });
          return Promise.reject(error);
        }
      );
  },
  improvements({ commit }, { timeframe = "1w", number = 10 }) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `Company/Improvements/${timeframe}/${number}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_IMPROVEMENTS", response.data);
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },
  peers({ commit }, { code, profile, orderBy, orderByDirection, skip = 0, limit = 10 }) {
    let query = Functions.Formatting.objectToQueryString({ profile, skip, limit, orderBy, orderByDirection });
    return axios
      .request({
        url: `Company/${code}/Peers${query}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_PROP", { code: Functions.Utils.cleanCode(code), prop: "peers", data: response.data });
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },
  recentNews({ commit }, { code, count = 5 }) {
    let query = Functions.Formatting.objectToQueryString({ count });
    return axios
      .request({
        url: `Company/${code}/RecentNews${query}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_PROP", { code: Functions.Utils.cleanCode(code), prop: "recentNews", data: response.data });
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },
  financials({ commit }, { code }) {
    return axios
      .request({
        url: `Company/${code}/Financials`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_PROP", { code: Functions.Utils.cleanCode(code), prop: "financials", data: response.data.data });
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
