const routes = [];

routes.push({
  path: "/terms",
  name: "Terms",
  components: {
    default: () => import(/* webpackChunkName: "public" */ "@/views/Public/Terms.vue")
  }
});

export default {
  routes
};
