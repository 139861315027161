let Full = {
  type: "button",
  color: "primary",
  kind: "full",
  class: "btn"
};

let kindHollow = { kind: "hollow" };
let Hollow = Object.assign({ ...Full }, kindHollow);

let colorPrimary = { color: "primary" };
let colorSecondary = { color: "secondary" };
let colorBackground = { color: "background" };
let colorWhite = { color: "white" };
let colorBlue = { color: "blue" };
let colorGray = { color: "gray" };
// let colorBlack = { color: "black" };
let colorSuccess = { color: "success" };
let colorError = { color: "error" };

let Link = {
  type: "button",
  color: "blue",
  kind: "link",
  class: "a"
};

let Plain = {
  type: "button",
  color: "inherit",
  kind: "plain",
  class: "btn"
};

export default {
  // base
  Full,
  Submit: Object.assign({ ...Full }, { type: "submit" }),
  Hollow,
  FullWhite: Object.assign({ ...Full }, colorWhite),
  FullDelete: Object.assign({ ...Full }, colorError, { class: "delete-btn" }),
  FullSuccess: Object.assign({ ...Full }, colorSuccess),

  HollowBackground: Object.assign({ ...Hollow }, colorBackground),
  HollowSecondary: Object.assign({ ...Hollow }, colorSecondary),
  HollowSuccess: Object.assign({ ...Hollow }, colorSuccess),
  HollowError: Object.assign({ ...Hollow }, colorError),
  HollowWhite: Object.assign({ ...Hollow }, colorWhite),
  HollowBlue: Object.assign({ ...Hollow }, colorBlue),
  HollowGray: Object.assign({ ...Hollow }, colorGray),

  // links
  Link,
  LinkLight: Object.assign({ ...Link }, colorPrimary),

  InlineLink: Object.assign({ ...Link }, { kind: "inline" }),

  //Plain
  Plain
};
