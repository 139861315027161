import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import mitt from "mitt";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import messages from "@/assets/dictionary.js";

const i18n = createI18n({
  locale: "en",
  messages
});
const emitter = mitt();

import multimedia from "./assets/multimedia.json";
store.commit("Multimedia/SET", { data: multimedia });

store.dispatch("User/getCurrent").finally(() => {
  let path = window.location.pathname;
  if (path == "/" && path != store.getters["User/roleHome"]) {
    window.location = store.getters["User/roleHome"];
  }

  const app = createApp(App)
    .use(store)
    .use(router)
    .use(i18n);
  app.config.globalProperties.emitter = emitter;
  app.mount("#app");
});
