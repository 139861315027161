const state = {};

const getters = {
  byKey: () => key => localStorage.getItem(key),
  setContextVar: () => data => localStorage.getItem(data.context + "-" + data.key)
};

const mutations = {
  SET(state, { key, value }) {
    localStorage.setItem(key, value);
  }
};

const actions = {
  setContextVar({ commit }, data) {
    let key = `${data.context}-${data.key}`;
    commit("SET", { key, value: data.value });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
