import { reactive, ref } from "vue";
import axios from "axios";
import Api from "@/functions/api.js";

const cleanState = {
  busy: ref(false),
  portfolios: reactive([])
};

const state = {
  cleanState,
  busy: cleanState.busy,
  portfolios: cleanState.portfolios
};

const getters = {
  busy: state => state.busy,
  portfolios: state => state.portfolios,
  allHoldings: (state, getters) =>
    getters.portfolios.reduce((h, p) => {
      h = h.concat(p.holdings);
      return h;
    }, []),
  isEmpty: (state, getters) => !getters.busy && getters.portfolios.length == 0,
  noHoldings: (state, getters) => !getters.busy && !getters.isEmpty && getters.allHoldings.length == 0
};

const mutations = {
  SET_BUSY(state, data) {
    state.busy = data;
  },
  SET_ALL(state, data) {
    state.portfolios = data;
  },
  SET_ONE(state, data) {
    let p = state.portfolios.findIndex(p => p.id == data.id);
    if (p > -1) {
      state.portfolios[p] = data;
    } else {
      state.portfolios.push(data);
    }
  }
};

const actions = {
  getPortfolios({ commit, dispatch }) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/Portfolio`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Api.getHeaders()
      })
      .then(
        response => {
          let details = [];
          response.data.forEach(portfolio => {
            details.push(dispatch("getPortfolio", portfolio.id));
          });
          return Promise.all(details).finally(() => {
            commit("SET_BUSY", false);
          });
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },

  addPortfolio({ commit }, data) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/Portfolio`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_ONE", response.data);
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },

  getPortfolio({ commit }, id) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/Portfolio/${id}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_ONE", response.data);
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },

  editPortfolio({ commit }, data) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/Portfolio/${data.id}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "put",
        data,
        headers: Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_ONE", response.data);
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },

  deletePortfolio({ commit }, id) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/Portfolio/${id}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "delete",
        headers: Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },

  addPortfolioHolding({ commit, dispatch }, data) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/Portfolio/${data.id}/Holding/${data.code}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        headers: Api.getHeaders()
      })
      .then(
        response => {
          dispatch("getPortfolio", data.id);
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },

  deletePortfolioHolding({ commit, dispatch }, data) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/Portfolio/${data.id}/Holding/${data.code}`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "delete",
        headers: Api.getHeaders()
      })
      .then(
        response => {
          dispatch("getPortfolio", data.id);
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
