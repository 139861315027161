import { reactive, ref } from "vue";

const cleanState = {
  busy: ref(false),
  multimedia: reactive({})
};

const state = {
  cleanState,
  busy: cleanState.busy,
  multimedia: cleanState.multimedia
};

const getters = {
  busy: state => state.busy,
  multimedia: state => state.multimedia || {},
  allMedia: state => state.multimedia.Medias || []
};

const mutations = {
  SET(state, { data }) {
    state.multimedia = data;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
