import _ from "lodash";

const state = {};
const getters = {};
const mutations = {};

const actions = {
  resetAllState({ dispatch }) {
    let modules = _.get(this, "_modules.root._children");
    for (const currentModule in modules) {
      if (Object.prototype.hasOwnProperty.call(modules[currentModule].state, "cleanState")) {
        dispatch("resetModuleState", currentModule);
      }
    }
  },
  resetModuleState({ rootState }, currentModule) {
    let cleanState = rootState[currentModule].cleanState;
    Object.keys(cleanState).forEach(key => {
      let cleanStateValue = rootState[currentModule].cleanState[key];
      if (_.isObject(cleanStateValue) && typeof cleanStateValue.then !== "function") {
        cleanStateValue = _.cloneDeep(cleanStateValue);
      }
      rootState[currentModule][key] = cleanStateValue;
    });
  }
};

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions
};
