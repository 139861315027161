import { reactive, ref } from "vue";
import axios from "axios";
import Api from "@/functions/api.js";
import SavedSession from "@/functions/savedSession.js";
import _ from "lodash";
import C from "@/assets/constants.js";

const cleanState = {
  authToken: ref(null),
  isLoggedIn: ref(false),
  userId: ref(null),
  userData: reactive({})
};

const state = {
  cleanState,
  authToken: SavedSession.getAuthToken() || cleanState.authToken,
  isLoggedIn: !!SavedSession.getAuthToken() || cleanState.isLoggedIn,
  userId: SavedSession.getUserId() || cleanState.userId,
  userData: cleanState.userData
};

const getters = {
  authToken: state => state.authToken,
  isLoggedIn: state => state.isLoggedIn,
  isAdmin: (state, getters) => getters.roles.includes(C.ACCESS.ADMIN),
  isMember: (state, getters) => getters.roles.includes(C.ACCESS.CLIENT),
  roles: state => _.get(state, "userData.roles", []),
  topRole: (state, getters) => {
    if (!getters.isLoggedIn) {
      return C.ACCESS.PUBLIC;
    }
    if (!_.get(state, "userData.emailValid", false)) {
      return C.ACCESS.INACTIVE;
    }
    let roles = getters.roles;
    if (roles.length == 0) {
      return C.ACCESS.UNPAID;
    }
    let topIndex = _.reduce(
      roles,
      (acc, r) => {
        let index = C.ACCESS_HIERARCHY.findIndex(h => C.ACCESS[h] == r);
        if (index > acc) {
          acc = index;
        }
        return acc;
      },
      0
    );
    return C.ACCESS[C.ACCESS_HIERARCHY[topIndex]];
  },
  roleHome: (state, getters) => {
    switch (getters.topRole) {
      case C.ACCESS.ADMIN:
      case C.ACCESS.CLIENT:
        return "/";
      case C.ACCESS.UNPAID:
        return "/pending-payment";
      case C.ACCESS.INACTIVE:
        return "/pending-activation";
      default:
        return "/";
    }
  },
  userId: state => state.userId,
  userData: state => state.userData,
  userDataValue: state => value => _.get(state, `userData[${value}]`, null)
};

const mutations = {
  SET_AUTH(state, data) {
    state.authToken = data.securityToken || state.securityToken;
    state.isLoggedIn = true;
    state.userId = data.member.id || null;
    SavedSession.set(state.authToken, state.userId);
  },
  SET_DATA(state, data) {
    state.userData = data;
  },
  CLEAR_AUTH(state) {
    state.authToken = cleanState.authToken;
    state.isLoggedIn = cleanState.isLoggedIn;
    state.userId = cleanState.userId;
    state.userData = cleanState.userData;
    SavedSession.clear();
  }
};

const actions = {
  login({ commit, dispatch }, data) {
    return axios
      .request({
        url: "Member/Login",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_AUTH", response.data);
          commit("SET_DATA", response.data.member);
          return dispatch("getCurrent");
        },
        error => {
          commit("CLEAR_AUTH");
          return Promise.reject(error);
        }
      );
  },

  logout({ commit, dispatch }) {
    return axios
      .request({
        url: "Member/Logout",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Api.getHeaders()
      })
      .then(() => {
        commit("CLEAR_AUTH");
        return dispatch("resetAllState", {}, { root: true });
      })
      .catch(() => {
        commit("CLEAR_AUTH");
        return dispatch("resetAllState", {}, { root: true });
      });
  },

  register({ commit }, data) {
    commit("CLEAR_AUTH");
    return axios
      .request({
        url: "Member/Register",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Api.getHeaders()
      })
      .then(
        response => {
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  changePassword(a, data) {
    return axios
      .request({
        url: "Member/ChangePassword",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Api.getHeaders()
      })
      .then(
        response => {
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  resetPasswordRequest({ commit }, data) {
    commit("CLEAR_AUTH");
    data["emailTemplateUrl"] = "https://connect.spotee.com.au/templates/PasswordResetRequestEmail.html";
    return axios
      .request({
        url: "Member/ResetPasswordRequestEmail",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Api.getHeaders()
      })
      .then(
        response => {
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  resetPassword({ commit }, data) {
    commit("CLEAR_AUTH");
    return axios
      .request({
        url: "Member/ResetPassword",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Api.getHeaders()
      })
      .then(
        response => {
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  validateEmail(a, data) {
    return axios
      .request({
        url: "Member/ValidateEmail",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Api.getHeaders()
      })
      .then(
        response => {
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  requestEmailValidation(a, data = {}) {
    data["emailTemplateUrl"] = "https://connect.spotee.com.au/templates/EmailValidationRequestEmail.html";
    return axios
      .request({
        url: "Member/RequestEmailValidation",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Api.getHeaders()
      })
      .then(
        response => {
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  getCurrent({ commit }) {
    return axios
      .request({
        url: "Member/Get",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_DATA", response.data.member);
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  refreshLogin({ commit }) {
    return axios
      .request({
        url: "Member/RefreshLogin",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "get",
        headers: Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_AUTH", response.data);
          commit("SET_DATA", response.data.member);
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  acceptTC() {
    return axios
      .request({
        url: "Member/AcceptTC",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        headers: Api.getHeaders()
      })
      .then(
        response => {
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  checkEmailInUse(a, data) {
    return axios
      .request({
        url: "Member/CheckEmailInUse",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data: {
          email: data.email
        },
        headers: Api.getHeaders()
      })
      .then(
        response => {
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  checkDisplayNameInUse(a, data) {
    return axios
      .request({
        url: "Member/CheckDisplayNameInUse",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data: {
          name: data.displayName
        },
        headers: Api.getHeaders()
      })
      .then(
        response => {
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  updateDetails({ dispatch }, data) {
    return axios
      .request({
        url: "Member/UpdateDetails",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Api.getHeaders()
      })
      .then(
        () => {
          return dispatch("getCurrent");
        },
        error => {
          return Promise.reject(error);
        }
      );
  },

  sendEmail(a, data) {
    return axios
      .request({
        url: "Member/SendEmail",
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data,
        headers: Api.getHeaders()
      })
      .then(
        response => {
          return response;
        },
        error => {
          return Promise.reject(error);
        }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
