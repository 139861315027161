import { reactive } from "vue";

const state = {
  routes: reactive([]),
  routerHistory: reactive([])
};

const getters = {
  routes: state => {
    return state.routes;
  },
  routerHistory: state => {
    return state.routerHistory;
  },
  hasHistory: state => {
    return state.routerHistory.length > 0;
  },
  hasPrevious: state => path => {
    let filterHistory = state.routerHistory.filter(h => h.path != path);
    return filterHistory.length > 0;
  },
  lastInHistory: state => {
    return state.routerHistory[state.routerHistory.length - 1] || false;
  }
};

const mutations = {
  routesConcat(state, payload) {
    state.routes = state.routes.concat(payload);
  },
  routePush(state, payload) {
    state.routes.push(payload);
  },
  historyPush(state, payload) {
    state.routerHistory.push(payload);
  },
  historyPop(state) {
    state.routerHistory.pop();
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
