import Auth from "./auth.js";
import Company from "./company.js";
import LuxSettings from "./luxSettings.js";
import Notice from "./notice.js";
import Public from "./public.js";

export default {
  Auth,
  Company,
  LuxSettings,
  Notice,
  Public
};
