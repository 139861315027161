import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import C from "@/assets/constants.js";
import Functions from "@/functions";
import Routes from "./routes";
import _ from "lodash";

let routes = [
  //CLIENT
  {
    path: "/dashboard",
    alias: "/",
    name: "Dashboard",
    components: {
      header: () => import(/* webpackChunkName: "client" */ "@/components/molecules/Header.vue"),
      default: () => import(/* webpackChunkName: "client" */ "@/views/Dashboard.vue"),
      footer: () => import(/* webpackChunkName: "client" */ "@/components/molecules/footers/Navigation.vue")
    },
    meta: {
      access: [C.ACCESS.CLIENT, C.ACCESS.ADMIN]
    }
  },
  {
    path: "/watchlist",
    name: "Watchlist",
    components: {
      header: () => import(/* webpackChunkName: "client" */ "@/components/molecules/Header.vue"),
      default: () => import(/* webpackChunkName: "client" */ "@/views/Watchlist.vue"),
      footer: () => import(/* webpackChunkName: "client" */ "@/components/molecules/footers/Navigation.vue")
    },
    meta: {
      access: [C.ACCESS.CLIENT, C.ACCESS.ADMIN]
    }
  }
];
routes = routes.concat(Routes.Auth.routes);
routes = routes.concat(Routes.Company.routes);
routes = routes.concat(Routes.Public.routes);
routes = routes.concat(Routes.Notice.routes);

store.commit("Router/routesConcat", routes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  to.params.from = from;
  if (to.matched.some(record => canRoute(record))) {
    if (!Functions.Validations.hasQueryParams(to) && Functions.Validations.hasQueryParams(from)) {
      next({ path: to.path, query: from.query });
    } else {
      next();
    }
  } else {
    next("/logout");
  }
});

router.afterEach(to => {
  store.commit("Router/historyPush", to);
});

const canRoute = item => {
  while (!_.has(item, "meta.access") && item.parent) {
    item = item.parent;
  }
  let access = _.get(item, "meta.access", [C.ACCESS.PUBLIC]);
  access = _.isFunction(access) ? item.meta.access() : access;
  access = _.isString(access) ? [access] : access;
  let accessFound = access.some(a => store.getters["User/topRole"] == a);
  return access == C.ACCESS.PUBLIC || accessFound;
};

export default router;
