import C from "@/assets/constants.js";
const routes = [];

routes.push({
  path: "/login",
  name: "Login",
  components: {
    default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/Login.vue")
  }
});

routes.push(
  {
    path: "/register",
    name: "Register",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/Register.vue")
    }
  },
  {
    path: "/register-success",
    name: "RegisterSuccess",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/RegisterSuccess.vue")
    }
  }
);

routes.push(
  {
    path: "/pending-activation",
    name: "PendingActivation",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/PendingActivation.vue")
    },
    meta: {
      access: C.ACCESS.INACTIVE
    }
  },
  {
    path: "/request-activation-success",
    name: "RequestActivationSuccess",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/RequestActivationSuccess.vue")
    }
  }
);

routes.push(
  {
    path: "/pending-payment",
    name: "PendingPayment",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/PendingPayment.vue")
    },
    meta: {
      access: C.ACCESS.UNPAID
    }
  },
  {
    path: "/StripePayments/:result",
    name: "StripePayments",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/StripePayments.vue")
    },
    props: {
      default: r => ({
        result: r.params.result
      })
    }
  }
);

routes.push(
  {
    path: "/forgot-password",
    name: "Forgot",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/ForgotPassword.vue")
    }
  },
  {
    path: "/forgot-password-success",
    name: "ForgotSuccess",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/Success.vue")
    }
  }
);

routes.push(
  {
    path: "/activate/:token",
    name: "Activate",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/Activate.vue")
    },
    props: {
      default: r => ({
        token: r.params.token
      })
    }
  },
  {
    path: "/activate-success",
    name: "ActivatSuccess",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/ActivateSuccess.vue")
    }
  },
  {
    path: "/activate-fail",
    name: "ActivateFail",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/ActivateFail.vue")
    }
  }
);

routes.push(
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/ChangePassword.vue")
    },
    props: {
      default: r => ({
        token: r.params.token
      })
    }
  },
  {
    path: "/reset-password-success",
    name: "ResetPasswordSuccess",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/Success.vue")
    }
  }
);

routes.push({
  path: "/change-password",
  name: "ChangePassword",
  components: {
    header: () => import(/* webpackChunkName: "auth" */ "@/components/molecules/Header.vue"),
    default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/ChangePassword.vue")
  },
  meta: {
    access: [C.ACCESS.CLIENT, C.ACCESS.ADMIN]
  }
});

routes.push({
  path: "/logout",
  name: "Logout",
  components: {
    default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/Logout.vue")
  }
});

routes.push(
  {
    path: "/profile",
    name: "Profile",
    components: {
      header: () => import(/* webpackChunkName: "auth" */ "@/components/molecules/Header.vue"),
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/Profile.vue"),
      footer: () => import(/* webpackChunkName: "client" */ "@/components/molecules/footers/Navigation.vue")
    },
    meta: {
      access: [C.ACCESS.CLIENT, C.ACCESS.ADMIN]
    }
  },
  {
    path: "/profile/edit/:field",
    name: "EditProfile",
    components: {
      header: () => import(/* webpackChunkName: "auth" */ "@/components/molecules/Header.vue"),
      default: () => import(/* webpackChunkName: "auth" */ "@/views/Auth/EditProfile.vue")
    },
    props: {
      default: r => ({
        field: r.params.field
      })
    },
    meta: {
      access: [C.ACCESS.CLIENT, C.ACCESS.ADMIN]
    }
  }
);

export default {
  routes
};
