import _ from "lodash";

const arrayToString = function(input) {
  if (_.isArray(input)) {
    input = input[0];
  }
  return input;
};

const cleanCode = function(input) {
  if (!_.isString(input)) {
    return input;
  }
  return input.split(".")[0];
};

function downloadFile({ content, contentType, fileName }) {
  const linkSource = `data:${contentType};base64,${encodeURI(content)}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

const toPromise = function(f) {
  return function() {
    return new Promise((resolve, reject) => {
      if (!f) {
        resolve(true);
      }
      const result = f.apply(null, Array.from(arguments));
      try {
        return result.then(resolve, reject); // promise.
      } catch (e) {
        if (e instanceof TypeError) {
          resolve(result); // resolve naked value.
        } else {
          reject(e); // pass unhandled exception to caller.
        }
      }
    });
  };
};

export default {
  arrayToString,
  cleanCode,
  downloadFile,
  toPromise
};
