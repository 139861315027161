import C from "@/assets/constants.js";
const routes = [];

routes.push({
  path: "/company/:companyCode",
  name: "Company",
  components: {
    header: () => import(/* webpackChunkName: "client" */ "@/components/molecules/Header.vue"),
    default: () => import(/* webpackChunkName: "company" */ "@/views/Company.vue"),
    footer: () => import(/* webpackChunkName: "client" */ "@/components/molecules/footers/Navigation.vue")
  },
  props: {
    default: r => ({
      companyCode: r.params.companyCode
    })
  },
  children: [
    {
      path: "",
      alias: "overview",
      name: "CompanyOverview",
      components: {
        default: () => import(/* webpackChunkName: "client" */ "@/components/molecules/company/Overview.vue")
      },
      props: {
        default: r => ({
          companyCode: r.params.companyCode
        })
      }
    },
    {
      path: "financial",
      name: "CompanyFinancial",
      components: {
        default: () => import(/* webpackChunkName: "client" */ "@/components/molecules/company/Financial.vue")
      },
      props: {
        default: r => ({
          companyCode: r.params.companyCode
        })
      }
    },
    {
      path: "announcements",
      name: "CompanyAnnouncements",
      components: {
        default: () => import(/* webpackChunkName: "client" */ "@/components/molecules/company/Announcements.vue")
      },
      props: {
        default: r => ({
          companyCode: r.params.companyCode
        })
      }
    },
    {
      path: "peers",
      name: "CompanyPeers",
      components: {
        default: () => import(/* webpackChunkName: "client" */ "@/components/molecules/company/Peers.vue")
      },
      props: {
        default: r => ({
          companyCode: r.params.companyCode
        })
      }
    }
  ],
  meta: {
    access: [C.ACCESS.CLIENT, C.ACCESS.ADMIN]
  }
});

export default {
  routes
};
