import { createStore } from "vuex";

import Root from "./root.js";
import Company from "./company.js";
import CompanyQuestions from "./companyQuestions.js";
import LocalSettings from "./localSettings.js";
import LuxScore from "./luxScore.js";
import Multimedia from "./multimedia.js";
import Notice from "./notice.js";
import Portfolio from "./portfolio.js";
import Router from "./router.js";
import StripePayment from "./stripePayment.js";
import User from "./user.js";
import Watchlist from "./watchlist.js";

export default createStore({
  actions: Root.actions,
  modules: {
    Company,
    CompanyQuestions,
    LocalSettings,
    LuxScore,
    Multimedia,
    Notice,
    Portfolio,
    Router,
    StripePayment,
    User,
    Watchlist
  }
});
