import C from "@/assets/constants.js";
const routes = [];

routes.push({
  path: "/newNotice",
  name: "NewNotice",
  components: {
    header: () => import(/* webpackChunkName: "client" */ "@/components/molecules/Header.vue"),
    default: () => import(/* webpackChunkName: "company" */ "@/views/NewNotice.vue")
  },
  meta: {
    access: [C.ACCESS.ADMIN]
  }
});

routes.push({
  path: "/editNotice/:noteId",
  name: "EditNotice",
  components: {
    header: () => import(/* webpackChunkName: "client" */ "@/components/molecules/Header.vue"),
    default: () => import(/* webpackChunkName: "company" */ "@/views/NewNotice.vue")
  },
  props: {
    default: r => ({
      noteId: r.params.noteId
    })
  },
  meta: {
    access: [C.ACCESS.ADMIN]
  }
});

export default {
  routes
};
