import { reactive, ref } from "vue";
import axios from "axios";
import Functions from "@/functions";

const cleanState = {
  busy: ref(false),
  payments: reactive([])
};

const state = {
  cleanState,
  busy: cleanState.busy,
  payments: cleanState.payments
};

const getters = {
  busy: state => state.busy,
  payments: state => state.payments
};

const mutations = {
  SET_BUSY(state, data) {
    state.busy = data;
  },
  SET_PAYMENTS(state, data) {
    state.payments = data;
  }
};

const actions = {
  Checkout({ commit }) {
    commit("SET_BUSY", true);
    return axios
      .request({
        url: `/StripePayment/Checkout`,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data: {
          product: "spoteeconnect",
          lineId: "subscription_monthly",
          cancelUrl: `${window.origin}/StripePayments/CheckoutCancel?reference={reference}`,
          successUrl: `${window.origin}/StripePayments/CheckoutSuccess?reference={reference}`
        },
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },

  CheckoutCallback({ commit }, data) {
    commit("SET_BUSY", true);
    let query = Functions.Formatting.objectToQueryString({ reference: data.reference });
    let url = `/StripePayment/${data.result}${query}`;
    return axios
      .request({
        url,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },

  GetPayments({ commit }) {
    commit("SET_BUSY", true);
    let url = `/StripePayment/GetPayments`;
    return axios
      .request({
        url,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        data: {
          count: 999
        },
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_BUSY", false);
          commit("SET_PAYMENTS", response.data.payments);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  },

  CancelSubscription({ commit }) {
    commit("SET_BUSY", true);
    let url = `/StripePayment/CancelSubscription`;
    return axios
      .request({
        url,
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        method: "post",
        headers: Functions.Api.getHeaders()
      })
      .then(
        response => {
          commit("SET_BUSY", false);
          return response;
        },
        error => {
          commit("SET_BUSY", false);
          return Promise.reject(error);
        }
      );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
